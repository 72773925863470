import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "immutable";
import PropTypes from "prop-types";

import AggregatedFromSimilarInfoPopUp from "../AggregatedFromSimilarInfoPopUp";
import InsightsDemographicsInfoPopUp from "./InsightsDemographicsInfoPopUp";

import { capitalizeWords } from "utils/case";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  innerLi: {
    position: "relative",
    gap: "1.5rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "start",
    padding: "1rem 0rem",
    height: "calc(100% - 1px)",
  },
  item: {
    borderBottom: `1px solid ${colours.outsideBorder}`,
    borderRight: `1px solid ${colours.outsideBorder}`,

    ":nth-child(3)": {
      borderBottom: "none",
    },
    ":nth-child(4)": {
      borderBottom: "none",
    },
    ":last-child": {
      borderRight: `1px solid ${colours.outsideBorder}`,
    },
    flex: "0 0 50%",

    [ScreenSizes.mdAndAbove]: {
      ":nth-child(3)": {
        borderBottom: `1px solid ${colours.outsideBorder}`,
      },
      ":nth-child(4)": {
        borderBottom: `1px solid ${colours.outsideBorder}`,
      },
      ":last-child": {
        borderRight: "none",
      },

      flexBasis: "0",
      flexGrow: "1",
      flex: "1 0 0",
    },
  },
  itemValueOuter: {
    margin: 0,
    fontSize: "0.8125rem",
    textAlign: "center",
    display: "flex",
    minHeight: "3rem",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    padding: "0 0.313rem",
  },
  itemValue: {
    ...gStyles.avalonBold,
    margin: 0,
    fontSize: "1.25rem",
    textAlign: "center",
  },
  itemLabel: {
    ...gStyles.avalonBold,
    display: "flex",
    padding: "0rem 1rem",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "0.2rem",

    color: colours.header,
    textAlign: "start",
    fontSize: "0.8125rem",
    letterDpacing: "-0.01625rem",
  },
  subLabel: {
    ...gStyles.fontBold,
    textAlign: "center",
    letterSpacing: "-0.02em",
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    color: "var(--color-neutral-d5)",
  },
  labelInner: {
    display: "flex",
    gap: "0.25rem",
    lineHeight: 1,
    alignSelf: "start",
  },
  info: {
    display: "flex",
    alignItems: "center",
    gap: "0.625rem",
    alignSelf: "start",
  },
  infoWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
  },
};

const popUpStyles = {
  outer: {
    width: "100%",
    [ScreenSizes.mdAndAbove]: {
      marginTop: 0,
      bottom: 30,
    },
  },
};

const lastItemPopUpStyle = {
  outer: {
    width: "100%",
    [ScreenSizes.mdAndAbove]: {
      marginTop: 0,
      bottom: 30,
      left: -120,
    },
  },
  triangle: {
    right: -10,
    left: "auto",
  },
};

const iconStyles = {
  infoIcon: {
    alignSelf: "center",
    display: "flex",
    fontSize: "1rem",
  },
};

const GridCardInfoItem = (props) => {
  const {
    item,
    itemsLength,
    index,
    podcast,
    showPopUp,
    handlePopUp,
    children,
  } = props;
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <li key={item.key} className={css(styles.item)}>
      <span className={css(styles.innerLi)}>
        <div className={css(styles.itemLabel)}>
          <div className={css(styles.labelInner)}>
            <FontAwesomeIcon icon={item.iconLabel} />
            <span>{item.label}</span>
          </div>
          {(item.info || item.aggregatedFromSimilar) && (
            <div className={css(styles.info)}>
              <div className={css(styles.infoWrapper)}>
                {item.aggregatedFromSimilar && (
                  <AggregatedFromSimilarInfoPopUp
                    title={item.label?.toLowerCase()}
                    podcast={podcast}
                    showPopUp={showPopUp}
                    handlePopUp={handlePopUp}
                    popUpStyles={
                      index === itemsLength - 1
                        ? lastItemPopUpStyle
                        : popUpStyles
                    }
                    styles={iconStyles}
                  />
                )}
                {item.info && (
                  <InsightsDemographicsInfoPopUp
                    title={item.label && capitalizeWords(item.label)}
                    info={item.info}
                    link={item.link}
                    podcast={podcast}
                    showPopUp={showPopUp}
                    handlePopUp={handlePopUp}
                    popUpStyles={
                      index === itemsLength - 1
                        ? lastItemPopUpStyle
                        : popUpStyles
                    }
                    helpIconStyles={iconStyles}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <span className={css(styles.itemValueOuter)}>
          {item.value && (
            <span
              className={css(styles.itemValue)}
              data-id={`${item.key}-value`}
            >
              {children}
            </span>
          )}
        </span>
        {item.subLabel && (
          <span className={css(styles.subLabel)}>{item.subLabel}</span>
        )}
      </span>
    </li>
  );
};

GridCardInfoItem.propTypes = {
  item: PropTypes.object,
  podcast: PropTypes.instanceOf(Map),
  showPopUp: PropTypes.object,
  handlePopUp: PropTypes.func,
};

export default GridCardInfoItem;
