import { faLightbulb } from "@fortawesome/free-regular-svg-icons/faLightbulb";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons/faBriefcase";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faChild } from "@fortawesome/free-solid-svg-icons/faChild";
import { faCity } from "@fortawesome/free-solid-svg-icons/faCity";
import { faFemale } from "@fortawesome/free-solid-svg-icons/faFemale";
import { faMale } from "@fortawesome/free-solid-svg-icons/faMale";
import { faSchool } from "@fortawesome/free-solid-svg-icons/faSchool";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faBadgeDollar } from "@fortawesome/pro-solid-svg-icons/faBadgeDollar";
import { faDollarSign } from "@fortawesome/pro-solid-svg-icons/faDollarSign";
import { faGrinStars } from "@fortawesome/pro-solid-svg-icons/faGrinStars";
import { faLanguage } from "@fortawesome/pro-solid-svg-icons/faLanguage";
import { faRingsWedding } from "@fortawesome/pro-solid-svg-icons/faRingsWedding";
import { css } from "aphrodite";
import { List, Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import Title from "components/Entities/Page/Title";
import NoResultsMessage from "pages/Common/NoResultsMessage";

import { insightStyles, secondaryTitleStyles } from "../InsightsStyles";
import FlagDemographics from "./FlagDemographics";
import InsightAudienceReachIncome from "./InsightAudienceReachIncome";
import InsightsColorLabel from "./InsightsColorLabel";
import InsightsColorLoader from "./InsightsColorLoader";
import DemographicFilterTitle from "./InsightsDemographics/DemographicFilterTitle";
import GridCardInfoItem from "./InsightsDemographics/GridCardInfoItem";
import InsightsMapChart from "./InsightsDemographics/InsightsMapChartAsync";
import InsightsSecondaryDemographics from "./InsightsDemographics/InsightsSecondaryDemographicsAsync";
import InsightsGenderColorLabel from "./InsightsGenderColorLabel";

import modalActions from "actions/modals";
import paginationActions from "actions/pagination";
import * as paginationConstants from "constants/pagination";
import { capitalizeWords } from "utils/case";

import useActionCreators from "hooks/useActionCreators";
import useList from "hooks/useList";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import faMaleFemale from "styles/icons/faMaleFemale";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...insightStyles,
  fallback: {
    marginTop: "2rem",
  },
  subtitle: {
    position: "absolute",
    display: "flex",
    gap: "0.75rem",
    right: "1rem",
    top: "1rem",
  },
  insightsSectionAudience: {
    display: "grid",
    gap: "1rem",

    marginTop: "3rem",

    flex: "auto",
    [ScreenSizes.xlAndAbove]: {
      flex: "1 1 0px",
    },
  },
  click: {
    border: "none",
    outline: "none",
    color: colours.primary,
    padding: 0,

    ":hover": {
      cursor: "pointer",
    },
  },
  medianFontSize: {
    fontSize: "2rem",
  },

  flexBox: {
    position: "relative",
    display: "flex",
    gap: "1.5rem",
    flexDirection: "column",
    borderTop: `1px solid ${colours.outsideBorder}`,
    padding: "1rem",
    borderBottomLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    [ScreenSizes.mdAndAbove]: {
      gap: "1.25rem",
      borderTop: "none",
    },
  },

  insightsContentPadding: {
    marginBottom: 0,
    padding: 0,

    [ScreenSizes.mdAndAbove]: {
      marginBottom: 0,
      padding: 0,
    },
    [ScreenSizes.xlAndAbove]: {
      marginBottom: 0,
      padding: 0,
    },
  },
  title: {
    ...gStyles.fontRegular,
    fontSize: "0.8125rem",
  },
  info: {
    ...gStyles.fontBold,
    fontSize: "0.8125rem",
  },
  titleContainer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: "1rem",
    marginBottom: "-1rem",
  },

  items: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    borderBottom: "none",
    borderTopLeftRadius: "0.5rem",
    borderTopRightRadius: "0.5rem",
    flexWrap: "wrap",
    [ScreenSizes.mdAndAbove]: {
      rowGap: "2rem",
      flexWrap: "nowrap",
    },
  },
};

const overrideCardItemsStyles = {
  item: {
    borderBottom: "none",
  },
};

const overridingFemaleGenderSkewStyles = {
  loaderContainer: {
    background: colours.darkPurple,
    direction: "rtl",
  },
  loader: {
    background: colours.brightBlue,
  },
  label: {
    color: colours.brightBlue,
    background: colours.fadedLightBlue,
  },
};

const overridingMaleGenderSkewStyles = {
  loaderContainer: {
    background: colours.brightBlue,
  },
  loader: {
    background: colours.darkPurple,
  },
  label: {
    color: colours.darkPurple,
    background: colours.darkPurpleForeground,
  },
};

const mixedGenderSkewStyles = {
  loaderContainer: {
    background: colours.brightBlue,
  },
  loader: {
    background: colours.darkPurple,
  },
  label: {
    color: "var(--color-neutral-d5)",
    background: "var(--color-neutral-d9)",
  },
};

const overrideMapChartStyles = {
  seperator: {
    display: "none",
  },
  mapOuter: {
    maxHeight: "13rem",
  },
};

const ROW_LIMIT = 6;
const AGGREGATED_FROM_SIMILAR = "aggregated_from_similar";
const AGGREGATED_FROM_CATEGORIES = "aggregated_from_categories";
const listKey = paginationConstants.HOME_PODCASTS_KEY;

const sortByPercentageDescending = (a, b) => {
  if (a.get("percentage") < b.get("percentage")) {
    return 1;
  }

  return -1;
};

const getHigherEducationValues = (proData) => {
  const educationLevels = proData.get("top_education_levels");

  if (educationLevels && educationLevels.size > 0) {
    // Get known percentages
    const graduatesData = educationLevels.find((educationData) =>
      educationData.get("title").includes("graduates")
    );
    const studentsData = educationLevels.find((educationData) =>
      educationData.get("title").includes("University students")
    );
    const noHigherData = educationLevels.find((educationData) =>
      educationData.get("title").includes("No higher")
    );

    // Calculate total known percentage
    // We want to throw out 'unknown' for now
    const totalKnownPercentage = [
      graduatesData,
      studentsData,
      noHigherData,
    ].reduce((sum, data) => (data ? sum + data.get("percentage") : sum), 0);

    // Calculate extrapolated percentages
    const extrapolatedPercentages = [
      graduatesData,
      studentsData,
      noHigherData,
    ].map((data) =>
      data
        ? Math.round((data.get("percentage") / totalKnownPercentage) * 100)
        : 0
    );

    // Calculate skew and value based on extrapolated percentages
    const highestPercentage = Math.max(...extrapolatedPercentages);

    let value;
    let percent = extrapolatedPercentages[0] + extrapolatedPercentages[1];
    if (
      extrapolatedPercentages[0] + extrapolatedPercentages[1] >= 50 &&
      extrapolatedPercentages[0] + extrapolatedPercentages[1] <= 54
    ) {
      value = "Mixed";
    } else {
      if (highestPercentage === extrapolatedPercentages[0]) {
        value = "Higher Education";
      } else if (highestPercentage === extrapolatedPercentages[1]) {
        value = "Higher Education";
      } else if (highestPercentage === extrapolatedPercentages[2]) {
        percent = extrapolatedPercentages[2];

        if (
          extrapolatedPercentages[2] >= 50 &&
          extrapolatedPercentages[2] <= 54
        ) {
          value = "Mixed";
        } else {
          value = "No Higher Education";
        }
      }
    }

    // Calculate percent based on extrapolated percentages
    if (percent < 10) {
      percent *= 10;
    }

    return {
      value: value,
      percent: percent,
    };
  }

  return {};
};

export function demographicSkewDescription(value, title, mixedTitle) {
  if (value > 80) {
    return (
      <span>
        <b>
          {title.includes("Higher")
            ? `High ${title} Skew `
            : `High ${title} Skew `}
        </b>
        : Over 80% of listeners
      </span>
    );
  } else if (value >= 55 && value <= 80) {
    return (
      <span>
        <b>{title} Skew:</b> 55-80% of listeners
      </span>
    );
  } else if (value >= 50 && value <= 54.99) {
    return (
      <span>
        <b>Mixed:</b> No {mixedTitle} skews over 54% of listeners
      </span>
    );
  } else {
    return (
      <span>
        <b>{`High ${title} Skew`}</b>
      </span>
    );
  }
}

export function getDemographicSkewTitle({ genderScore, value }) {
  return demographicSkewDescription(
    genderScore,
    capitalizeWords(value),
    "gender"
  );
}

export const getAgeRange = (age) => {
  // strings given back are based on filter values
  if (age >= 18 && age <= 24) {
    return "18-24";
  } else if (age >= 25 && age <= 34) {
    return "25-34";
  } else if (age >= 35 && age <= 44) {
    return "35-44";
  } else if (age >= 45 && age <= 54) {
    return "45-54";
  } else if (age >= 55) {
    return "55";
  } else {
    return null;
  }
};

export const getParentalStatusMappedValue = (parentalSkew) => {
  let value, label;

  switch (parentalSkew) {
    case "highNonParent":
      value = 85;
      label = "Not Parents";
      break;
    case "nonParent":
      value = 60;
      label = "Not Parents";
      break;
    case "mixed":
      value = 50;
      label = "Mixed";
      break;
    case "parent":
      value = 60;
      label = "Parents";
      break;
    case "highParent":
      value = 85;
      label = "Parents";
      break;
    default:
      value = null;
      label = null;
  }

  return { value, label };
};

const InsightAudience = (props) => {
  const { podcast, proData, handlePopUp, showPopUp, hideTitle } = props;
  const { styles } = useStyles(baseStyles, props);

  const { activeFilters, availableFilters } = useList(listKey);

  const { showModal, getFilters } = useActionCreators({
    showModal: modalActions.showModal,
    getFilters: paginationActions.getFilters,
  });

  useEffect(() => {
    getFilters(listKey, "podcast", false);
  }, [getFilters]);

  const reachIncome = useMemo(
    () => proData && proData.getIn(["income", "income_tier"]),
    [proData]
  );

  const isAggregatedFromSimilar = useCallback(
    (key) =>
      [AGGREGATED_FROM_SIMILAR, AGGREGATED_FROM_CATEGORIES].includes(
        proData?.getIn(["source_types", key])
      ),
    [proData]
  );

  const determindedByInfoText = (key) => {
    return isAggregatedFromSimilar(key)
      ? "as aggregated from similar podcasts"
      : "as determined by predictive models";
  };

  function marriageStatusPercentage(percentage, title) {
    if (percentage < 50) {
      return title ? (
        "Not Married"
      ) : (
        <div className={css(styles.title)}>
          Mostly <span className={css(styles.info)}>Not Married</span>
        </div>
      );
    } else if (percentage >= 50 && percentage <= 54) {
      return title ? "Mixed" : <div className={css(styles.info)}>Mixed</div>;
    } else {
      return title ? (
        "Married"
      ) : (
        <div className={css(styles.title)}>
          Mostly <span className={css(styles.info)}>Married</span>
        </div>
      );
    }
  }

  const genderScore = proData?.getIn(["gender_distribution", "bias"]) * 100;

  const filterIncomeTier = (income) => {
    const litDollarSigns = income?.length;

    switch (litDollarSigns) {
      case 0:
        return "income-tier-0";

      case 1:
        return "income-tier-1";

      case 2:
        return "income-tier-2";

      case 3:
        return "income-tier-3";

      case 4:
        return "income-tier-4";

      default:
        return "income-tier-0";
    }
  };

  function getScoreGenderFilter(score, gender) {
    // strings given back are based on filter values
    if (gender === "male") {
      if (score >= 50 && score <= 54.99) {
        return "low";
      } else if (score > 80) {
        return "highmale";
      } else if (score > 54.99 && score < 80) {
        return "moderatemale";
      }
    } else if (gender === "female") {
      if (score >= 50 && score <= 54.99) {
        return "low";
      } else if (score > 80) {
        return "highfemale";
      } else if (score > 54.99 && score < 80) {
        return "moderatefemale";
      }
    }
  }

  const { value: topParentalStatusScore, label: parentalStatusLabel } = useMemo(
    () => proData && getParentalStatusMappedValue(proData.get("parental_skew")),
    [proData]
  );

  const items = [
    proData.getIn(["gender_distribution", "value"]) &&
      proData.getIn(["gender_distribution", "bias"]) && {
        key: "gender_distribution",
        icon: { male: faMale, female: faFemale }[
          proData.getIn(["gender_distribution", "value"])
        ],
        info: `Our estimate of the gender distribution (male-female skew) of this podcast's audience, ${determindedByInfoText(
          "gender_bias_percentage"
        )}.`,
        link: "https://support.podchaser.com/en/articles/10001497-gender-skews-insights",
        aggregatedFromSimilar: isAggregatedFromSimilar(
          "gender_bias_percentage"
        ),
        value: proData.has("gender_distribution") && (
          <InsightsColorLoader
            score={genderScore}
            label={
              <InsightsGenderColorLabel
                genderScore={genderScore}
                genderValue={proData.getIn(["gender_distribution", "value"])}
              />
            }
            title={getDemographicSkewTitle({
              genderScore,
              value: proData.getIn(["gender_distribution", "value"]),
            })}
            background={colours.darkPurple}
            styles={
              genderScore >= 50 && genderScore <= 54.99
                ? mixedGenderSkewStyles
                : proData.getIn(["gender_distribution", "value"]) === "male"
                ? overridingMaleGenderSkewStyles
                : overridingFemaleGenderSkewStyles
            }
          />
        ),
        label: "Gender",
        filter: availableFilters?.get("gender_bias"),
        filterValue: getScoreGenderFilter(
          genderScore,
          proData.getIn(["gender_distribution", "value"])
        ),
        iconLabel: faMaleFemale,
        preview: {
          type: "range",
          min: 0,
          max: 100,

          values: [
            {
              start: 0,
              end: proData.getIn(["gender_distribution", "bias"]) * 100,
              color:
                proData.getIn(["gender_distribution", "value"]) === "male"
                  ? "#74b4ff"
                  : "#f694d6",
              title: `${Math.round(
                proData.getIn(["gender_distribution", "bias"]) * 100
              )}% ${capitalizeWords(
                proData.getIn(["gender_distribution", "value"])
              )}`,
            },
            {
              start: proData.getIn(["gender_distribution", "bias"]) * 100,
              end: 100,
              color: "#a7a7a7",
              title: `${Math.round(
                (1 - proData.getIn(["gender_distribution", "bias"])) * 100
              )}% Other`,
            },
          ],
        },
      },
    proData.getIn(["age", "median"]) && {
      key: "age_median",
      value: (
        <BasicTooltip
          renderTooltip={() => (
            <span data-testid="age_median_tooltip">
              <b>Age:</b> {Math.round(proData.getIn(["age", "median"]))} years
              old{" "}
            </span>
          )}
        >
          {(tooltipProps) => (
            <span
              data-testid="age_median"
              {...tooltipProps}
              className={css(styles.medianFontSize)}
            >
              {Math.round(proData.getIn(["age", "median"]))}yo
            </span>
          )}
        </BasicTooltip>
      ),
      label: "Age",
      filter: availableFilters?.get("listener_age_range"),
      filterValue: getAgeRange(Math.round(proData.getIn(["age", "median"]))),

      iconLabel: faChild,
      info: `Our estimate of how old the average listener of this podcast is, ${determindedByInfoText(
        "median_age"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("median_age"),
      preview: {
        type: "graph",
        minX: 0,
        maxX: 100,
        minY: 0,
        maxY: 100,
        values: [
          [0, 10],
          [20, 40],
          [40, 30],
          [60, 5],
          [80, 45],
          [100, 100],
        ],
      },
    },
    proData.getIn(["audience_language", "name"]) && {
      key: "audience_language",
      icon: faLanguage,
      value: `${Math.round(
        proData.getIn(["audience_language", "percentage"]) * 100
      )}% ${proData.getIn(["audience_language", "language", "name"])}`,
      label: "Audience Language",
      aggregatedFromSimilar: isAggregatedFromSimilar("top_native_languages"),
    },
    !!reachIncome && {
      key: "income_tier",
      iconLabel: faDollarSign,
      value: <InsightAudienceReachIncome income={reachIncome} />,
      label: "Income",
      info: `Our estimate of how much the average listener of this podcast earns (more green dollar signs means higher income), as determined on predictive models.`,
      link: "https://support.podchaser.com/en/articles/9897661-understanding-income-tiers",
      aggregatedFromSimilar: isAggregatedFromSimilar("income_tier"),
      filter: availableFilters?.get("income_tier"),
      filterValue: filterIncomeTier(reachIncome),
    },
    proData.get("parental_skew") && {
      key: "parental_skew",
      iconLabel: faChild,
      info: `Our estimate of the parental status distribution (parents vs. non-parents skew) of this podcast's audience, ${determindedByInfoText(
        "parental_skew"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("parental_skew"),
      filter: availableFilters?.get("top_parental_status"),
      filterValue: proData.get("parental_skew"),
      value: (
        <InsightsColorLoader
          withSeparator
          score={topParentalStatusScore}
          label={
            <InsightsColorLabel
              label={parentalStatusLabel}
              labelStyle={{
                color:
                  parentalStatusLabel === "Mixed"
                    ? "var(--color-neutral-d5)"
                    : parentalStatusLabel === "Parents"
                    ? colours.brightPink
                    : colours.darkishBlue,
                background:
                  parentalStatusLabel === "Mixed"
                    ? "var(--color-neutral-d9)"
                    : parentalStatusLabel === "Parents"
                    ? colours.fadedLightPurple
                    : colours.darkishBlueForeground,
              }}
            />
          }
          title={demographicSkewDescription(
            topParentalStatusScore,
            parentalStatusLabel,
            "parent"
          )}
          background={
            parentalStatusLabel === "Not Parents"
              ? colours.darkishBlue
              : colours.brightPink
          }
          styles={
            parentalStatusLabel === "Not Parents" && {
              loaderContainer: {
                background: colours.brightPink,
                direction: "rtl",
              },
            }
          }
        />
      ),
      label: "Parental Status",
    },
  ].filter((i) => !!i);

  function audienceItemValue(value) {
    // Used for Interests/Influencers/Brands/Cities/Jobs/Employers
    if (value > 50) {
      return (
        <span>
          <b>Very High:</b> Over 50% of listeners
        </span>
      );
    } else if (value >= 25 && value <= 50) {
      return (
        <span>
          <b>High:</b> 25-50% of listeners
        </span>
      );
    } else if (value >= 10 && value < 25) {
      return (
        <span>
          <b>Moderate:</b> 10-25% of listeners
        </span>
      );
    } else if (value >= 1 && value < 10) {
      return (
        <span>
          <b>Low:</b> 1-10% of listeners
        </span>
      );
    } else {
      return (
        <span>
          <b>Niche:</b> Less than 1% of listeners
        </span>
      );
    }
  }

  function ethnicityItemValue(value) {
    // Ethnicity

    if (value > 75) {
      return (
        <span>
          <b>Very High:</b> Over 75% of listeners
        </span>
      );
    } else if (value >= 50 && value <= 75) {
      return (
        <span>
          <b>High:</b> 50-75% of listeners
        </span>
      );
    } else if (value >= 25 && value < 50) {
      return (
        <span>
          <b>Moderately High:</b> 25-50% of listeners
        </span>
      );
    } else if (value >= 15 && value < 25) {
      return (
        <span>
          <b>Moderately Low:</b> 15-25% of listeners
        </span>
      );
    } else if (value >= 5 && value < 15) {
      return (
        <span>
          <b>Low:</b> 5-15% of listeners
        </span>
      );
    } else {
      return (
        <span>
          <b>Niche:</b> Less than 5% of listeners
        </span>
      );
    }
  }

  const { value: higherEducationValue, percent: higherEducationPercent } =
    getHigherEducationValues(proData);

  const secondaryColumns = [
    proData.get("top_cities") && {
      label: "Top Cities",
      info: `Our estimate of the top cities where this podcast's audience resides, ${determindedByInfoText(
        "top_interests"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("top_cities"),
      icon: faCity,
      items: proData
        .get("top_cities")
        .sort(sortByPercentageDescending)
        .filter((item) => item.get("percentage") > 0)
        .slice(0, ROW_LIMIT)
        .map((item) => ({
          label: item.get("title"),
          title: audienceItemValue(
            Math.round(item.get("percentage") * 10000) / 100
          ),
        })),
    },
    proData.get("top_interests") && {
      label: "Interests",
      info: `Our estimate of the most common subjects listeners of this podcast are interested in (sorted by audience size), ${determindedByInfoText(
        "top_interests"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("top_interests"),

      icon: faLightbulb,
      items: proData
        .get("top_interests")
        .sort(sortByPercentageDescending)
        .filter((item) => item.get("percentage") > 0)
        .slice(0, ROW_LIMIT)
        .map((item) => ({
          label: item.get("title"),
          title: audienceItemValue(
            Math.round(item.get("percentage") * 10000) / 100
          ),
        })),
    },
    proData.get("top_occupations") && {
      label: "Jobs",
      info: `Our estimate of the most common job titles held by listeners of this podcast (sorted by audience size), ${determindedByInfoText(
        "top_occupations"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("top_occupations"),

      icon: faBriefcase,
      items: proData
        .get("top_occupations")
        .sort(sortByPercentageDescending)
        .filter((item) => item.get("percentage") > 0)
        .slice(0, ROW_LIMIT)
        .map((item) => ({
          label: item.get("title"),
          title: audienceItemValue(
            Math.round(item.get("percentage") * 10000) / 100
          ),
        })),
    },
    proData.get("top_races") && {
      label: "Ethnicities",
      info: `Our estimate of the ethnicity of this podcast's listeners (sorted by audience size), ${determindedByInfoText(
        "top_races"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("top_races"),
      icon: faUsers,
      items: proData
        .get("top_races")
        .sort(sortByPercentageDescending)
        .filter((item) => item.get("percentage") > 0)
        .concat(
          proData
            .get("top_races")
            .filter((item) => item.get("percentage") === 0)
        )
        .slice(0, ROW_LIMIT)
        .map((item) => ({
          label: item.get("title"),
          title: ethnicityItemValue(
            Math.round(item.get("percentage") * 10000) / 100
          ),
        })),
    },
    proData.get("top_employers") && {
      label: "Employers",
      info: `Our estimate of what companies the listeners of this podcast are most likely to work for (sorted by audience size), ${determindedByInfoText(
        "top_employers"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("top_employers"),

      icon: faBuilding,
      items: proData
        .get("top_employers")
        .sort(sortByPercentageDescending)
        .sort(sortByPercentageDescending)
        .filter((item) => item.get("percentage") > 0)
        .concat(
          proData
            .get("top_employers")
            .filter((item) => item.get("percentage") === 0)
        )
        .slice(0, ROW_LIMIT)
        .map((item) => ({
          label: item.get("title"),
          title: audienceItemValue(
            Math.round(item.get("percentage") * 10000) / 100
          ),
        })),
    },
    proData.get("top_brands") && {
      label: "Brands",
      info: `Our estimate of the brands that listeners of this podcast are most likely to be interested in (sorted by audience size), ${determindedByInfoText(
        "top_brands"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("top_brands"),
      icon: faBadgeDollar,
      items: proData
        .get("top_brands")
        .sort(sortByPercentageDescending)
        .filter((item) => item.get("percentage") > 0)
        .slice(0, ROW_LIMIT)
        .map((item) => ({
          label: item.get("title"),
          title: audienceItemValue(
            Math.round(item.get("percentage") * 10000) / 100
          ),
        })),
    },
    proData.get("top_influences") && {
      label: "Influencers",
      info: `Our estimate of the celebrities listeners of this podcast are most likely to follow on social media (sorted by audience size), ${determindedByInfoText(
        "top_influences"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("top_influences"),
      icon: faGrinStars,
      items: proData
        .get("top_influences")
        .sort(sortByPercentageDescending)
        .filter((item) => item.get("percentage") > 0)
        .slice(0, ROW_LIMIT)
        .map((item) => ({
          label: item.get("title"),
          title: audienceItemValue(
            Math.round(item.get("percentage") * 10000) / 100
          ),
        })),
    },
    proData.get("top_marital_status") && {
      icon: faRingsWedding,
      label: "Marital Status",
      info: `Our estimate of whether or not the majority of this podcast's audience are mostly married or single, ${determindedByInfoText(
        "top_marital_status"
      )}.`,
      aggregatedFromSimilar: isAggregatedFromSimilar("top_marital_status"),
      items: List([
        {
          label: marriageStatusPercentage(
            Math.round(
              proData.getIn(["top_marital_status", 0, "percentage"]) * 100
            )
          ),
          title: demographicSkewDescription(
            Math.round(
              proData.getIn(["top_marital_status", 0, "percentage"]) * 100
            ),
            marriageStatusPercentage(
              Math.round(
                proData.getIn(["top_marital_status", 0, "percentage"]) * 100
              ),
              true
            ),
            "marital status"
          ),
        },
      ]),
    },
    proData.get("top_education_levels") &&
      higherEducationValue && {
        icon: faSchool,
        label: "Education Level",
        info: `Our estimate of whether or not the majority of this podcast's audience have attended college/university, ${determindedByInfoText(
          "top_education_levels"
        )}.`,
        aggregatedFromSimilar: isAggregatedFromSimilar("top_education_levels"),

        items: List([
          {
            label: (
              <div data-testid="education_level_label">
                {!(
                  higherEducationPercent >= 50 && higherEducationPercent <= 54
                ) && <span className={css(styles.title)}>Mostly </span>}
                <span className={css(styles.info)}>{higherEducationValue}</span>
              </div>
            ),
            title: demographicSkewDescription(
              higherEducationPercent,
              `'${capitalizeWords(higherEducationValue)}'`,
              "education level"
            ),
          },
        ]),
      },
  ].filter((i) => !!i);

  const triggerConciergeQuestionModal = useCallback(() => {
    showModal("proConcierge", {
      entity: podcast,
      entityType: "podcast",
      subject: "report issue",
      proData,
    });
  }, [showModal, podcast, proData]);

  if (!podcast || !proData) {
    return null;
  }

  const hasContent =
    (items && items.length > 0) ||
    (secondaryColumns && secondaryColumns.length > 0);

  if (!items.length && !secondaryColumns.length) {
    return (
      <>
        <div className={css(styles.titleContainer, styles.fallback)}>
          <Title
            title="Audience Demographics"
            TitleComponent="h2"
            styles={secondaryTitleStyles}
            noTopPadding
          />
        </div>
        <NoResultsMessage
          entity={podcast}
          entityType={"podcast"}
          label="demographics details"
          subject="request demographics"
          proData={proData}
          content={
            <span>
              We currently do not provide audience demographics for this
              podcast. To report an error,{" "}
              <button
                className={css(styles.click)}
                onClick={triggerConciergeQuestionModal}
              >
                click here.
              </button>
            </span>
          }
        />
      </>
    );
  }

  return (
    <section
      className={css(
        styles.insightsSection,
        styles.insightsSectionAudience,
        styles.container
      )}
      data-id="podcast-insights-audience-section"
      id="podcast-insights-audience-section"
    >
      {!hideTitle && (
        <div className={css(styles.titleContainer)}>
          <Title
            title="Audience Demographics"
            TitleComponent="h2"
            styles={secondaryTitleStyles}
            noTopPadding
          />
          <FlagDemographics podcast={podcast} />
        </div>
      )}
      {hasContent && (
        <div
          className={css(styles.insightsContent, styles.insightsContentPadding)}
        >
          {items && items.length > 0 && (
            <ul className={css(styles.items)}>
              {items?.map((item, index) => {
                return (
                  <GridCardInfoItem
                    styles={
                      !secondaryColumns?.length &&
                      !proData.get("top_countries")?.size &&
                      overrideCardItemsStyles
                    }
                    item={item}
                    podcast={podcast}
                    handlePopUp={handlePopUp}
                    showPopUp={showPopUp}
                    itemsLength={items?.length}
                    index={index}
                  >
                    <DemographicFilterTitle
                      title={item.value}
                      filter={item.filter}
                      filters={activeFilters}
                      podcast={podcast}
                      filterValue={item.filterValue}
                    />
                  </GridCardInfoItem>
                );
              })}
            </ul>
          )}
          {(proData.get("top_countries")?.size > 0 ||
            secondaryColumns?.length > 0) && (
            <div className={css(styles.flexBox)}>
              {proData.get("top_countries")?.size > 0 && (
                <InsightsMapChart
                  topCountries={proData?.get("top_countries")}
                  filters={activeFilters}
                  filter={availableFilters?.get("top_countries")}
                  aggregatedFromSimilar={isAggregatedFromSimilar(
                    "top_countries"
                  )}
                  handlePopUp={handlePopUp}
                  showPopUp={showPopUp}
                  podcast={podcast}
                  styles={
                    !secondaryColumns?.length > 0 && overrideMapChartStyles
                  }
                />
              )}

              {secondaryColumns && secondaryColumns?.length > 0 && (
                <InsightsSecondaryDemographics
                  columns={secondaryColumns}
                  podcast={podcast}
                  handlePopUp={handlePopUp}
                  showPopUp={showPopUp}
                />
              )}
            </div>
          )}
        </div>
      )}
    </section>
  );
};

InsightAudience.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  proData: PropTypes.instanceOf(Map),
};

InsightAudience.defaultProps = {
  podcast: null,
  proData: null,
};

export default InsightAudience;
